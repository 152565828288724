import { useStaticQuery, graphql } from 'gatsby';

export const useEventsData = () => {
  const { allNodeEvent } = useStaticQuery(
    graphql`
      query EventsData {
        allNodeEvent(
          filter: { isOver: { eq: false } }
          sort: { fields: field_date___value, order: ASC }
        ) {
          nodes {
            title
            id
            body {
              summary
            }
            field_date {
              value(formatString: "dddd, MMMM D")
              format
            }
            dateShort: field_date {
              value(formatString: "MM/DD")
            }
            date: field_date {
              full: value(formatString: "dddd, MMMM D")
              medium: value(formatString: "MMMM D, Y")
              time: value(formatString: "h:mma")
              removalTime: end_value
              start: value(formatString: "YYYY-MM-DD")
              end: end_value(formatString: "YYYY-MM-DD")
              format
            }
            field_image {
              imageDerivatives {
                links {
                  w344_16_9 {
                    href
                  }
                  w90_1_1 {
                    href
                  }
                }
              }
              alt
            }
            srcSet: field_image {
              ...eventSrcSet16x9Fragment
              ...eventSrcSet10x7Fragment
            }
            relationships {
              field_tags {
                name
                drupal_id
                relationships {
                  parent {
                    drupal_id
                    name
                  }
                }
              }
              field_venue {
                name: title
                drupal_id
              }
              field_series {
                name
                drupal_id
              }
              type: node_type {
                name
                drupal_id
              }
            }
            path {
              alias
            }
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.
  const events = allNodeEvent.nodes.map((item) => {
    // Grab all the srcset data and creating a plain
    // array of all the image urls.
    const ratio10x7links = item?.srcSet?.ratio10x7?.links ?? {};
    const ratio10x7 = ratio10x7links
      ? Object.keys(ratio10x7links).map((value) => ratio10x7links[value].href)
      : [];
    const ratio16x9links = item?.srcSet?.ratio16x9?.links ?? {};
    const ratio16x9 = ratio16x9links
      ? Object.keys(ratio16x9links).map((value) => ratio16x9links[value].href)
      : [];
    item.relationships.field_tags.name =
      item?.relationships?.field_tags?.relationships?.parent[0]?.name;

    return {
      title: item?.title,
      id: item?.id,
      type: item?.relationships?.type?.name,
      summary: item?.body.summary,
      startDate: item?.field_date.value,
      dateShort: item?.dateShort?.value,
      formattedDate: item?.field_date.format,
      // We need additional date data so we can filter out events.
      date: item?.date,
      smallImage: {
        alt: item?.field_image?.alt,
        // eslint-disable-next-line camelcase
        src: item?.field_image?.imageDerivatives?.links?.w90_1_1?.href
      },
      largeImage: {
        alt: item?.field_image?.alt,
        // eslint-disable-next-line camelcase
        src: item?.field_image?.imageDerivatives?.links?.w344_16_9?.href
      },
      srcSet: {
        alt: item?.field_image?.alt,
        srcs: {
          ratio10x7,
          ratio16x9
        }
      },
      // So this is just the _first_ tag in the list since that's all we can display.
      // We may need to redo this later.
      tag: item?.relationships?.field_tags[0]?.name,
      parentTag:
        item?.relationships?.field_tags[0]?.relationships?.parent[0]?.name,
      parentTagId:
        item?.relationships?.field_tags[0]?.relationships?.parent[0]?.drupal_id,
      url: item?.path?.alias,
      // Preserve the original relationship structure so it's easier to figure out the filterable
      // fields later.
      relationships: item?.relationships
    };
  });

  return events;
};
